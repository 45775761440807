@import "../../styles/variables";

.logo__container{
  background-color: white;
  display: flex;
  padding: 11px 16.3px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  @include tablet {
    padding: 13.5px 20px;
  }
  a{
    text-decoration: none;
  }
}

.logo__text{
  color: black;
  font-style: normal;
  text-wrap: nowrap;
  font-family: "GTAmerica-medium";
  font-weight: 500;  
  font-size: 15px;
  line-height: 18px;

  @include tablet {
    font-size: 18px;
    line-height: 22px;
  }
}
.logo__frog-logo{
  width: 70px;
  height: auto;
  @include tablet { 
    width: 88px;
  }
}

.logo__link{
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}