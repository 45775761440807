@import "../../styles/variables";

.results__hero-wrap {
  max-width: 1344px;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  @include tablet {
    padding: 32px;
  }
  @include mobile {
    padding: 8px 16px 0;
  }

  @include left-right-padding;
}

.results__hero {
  width: 100%;
  background: $white;
  padding: 24px 20px 32px;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  @include tablet {
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
  }
}

.results__score {
  margin-bottom: 24px;
  @include tablet {
    flex-basis: 30%;
  }
  @include desktop {
    flex-basis: 30%;
  }

}

.results__image-wrapper {
  width: auto;
  transform: translateY(36px);
  @include tablet {
    align-self: end;
    flex-basis: 20%;
  }
  @include desktop {
    flex-basis: 30%;
  }
}

.results__image--container {
  display: flex;
  justify-content: flex-end;
  @include tablet {
    right: 20px;
  }
}

.results__image {
  flex:  0 0 auto;
  width: 100%;
}

.results__description {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 24px;
  @include tablet {
    padding-left: 24px;
    flex-basis: 50%;
    font-size: 24px;
    line-height: 30px;
  }
  @include desktop {
    flex-basis: 40%;
    padding-left: 48px;
    padding-right: 48px;
  }
}

.results__description-title {
  font-family: "GTAmerica-medium";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  margin:40px 0 16px 0;
  @include mobile {
    margin-top: 20px;
  }
}

.results__title {
  margin: 24px 0;
  font-family: QuartoLight;
  position: relative;
  letter-spacing: 0em;
  padding-left: 12px;
  font-size: 60px;
  line-height: 52px;
  @include desktop{
    font-size: 96px;
    line-height: 81px;
  }
  sup{
    font-size: 120px;
    top:-40px;
    left:10px;
  }
  sub{
    font-size: 96px;;
  }
}
.results__score-numbers{
  display: block;
  margin-left:-10px;
  margin-top: 88px;
  @include desktop {
    margin-top: 64px;
  }
}


.results__result {
  background: linear-gradient($primary2-light 50%, $primary2);
  background-clip: text;
  -webkit-text-fill-color: transparent;

}

.results__content {
  margin: 0 auto;
  padding: 0 16px 32px;
  max-width: 1344px;

  @include tablet {
    padding-bottom: 32px;
  }

  @include left-right-padding;

  &--centered {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 48px;
    margin-right: auto;
    margin-left: auto;

    @include tablet {
      margin-bottom: 80px;
    }
  }
}

.results__left-container {
  font-family: QuartoLight;
  font-weight: 300;
}

.results__idean-promo {
  padding: 48px 20px 0;
  width: 100%;
  background-color: $black;
  color: $white;
  padding-bottom: 1px;
  @include tablet {
    padding: 60px 20px 1px;
  }
}

.results__roundel {
  width: 200px;
  height: 200px;
  background-color: $black;
  color: $white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 50px;
}

.results__list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
  justify-content: space-between;

  > * {
    flex-basis: 100%;
  }

  @include tablet {
    > * {
      flex-basis: 49%;
    }
  }
  @include desktop {
    > * {
      flex-basis: 49%;
    }
  }
}

.results__section-titles {
  text-align: left;
  align-self: flex-start;
  font-size: 32px;
  margin-bottom: 0;
  margin-top: 64px;
  margin-bottom: 64px;

  @include tablet {
    font-size: 40px;
  }
}

.results__section-subtitles {
  margin-top: 0;
  font-size: 22px;
  font-weight: 600;
}

.results__blurb {
  text-align: left;
  max-width: 640px;
  font-family: "GTAmerica";
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;


  @include tablet {
    position: relative;
    z-index: 2;
    margin: 0 0 32px 0;
  }
}

.results__share {
  @include button;
}

.results__footer-item {
  background-color: $white;
  width: 100%;

  &--inverse {
    background-color: $black;
    color: $white;
  }

  &--frog-promo {
    background-color: $background;
  }
}

.results__footer-item-container {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: 32px 20px 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @include tablet{
    justify-content: space-between;
    padding: 48px 20px 48px;

  }
}

.results__footer-item-title  {
  flex-basis: 100%;

  h2{
    font-family: QuartoLight;
    font-size: 80px;
    line-height: 80px;
  }
  @include tablet{
    flex-basis: 50%;
    padding-right: 24px;
  }
  
  @include desktop{
    padding-right: 48px;
  }
  
  .results__footer-item--frog-promo & {
    h2 {
      font-size: 49px;
      font-weight: 300;
      line-height: 50px;
    }
    @include tablet{
      flex-basis: 75%;
    }
  }
}

.results__footer-item-content {
  padding: 20px 0;
  flex-basis: 100%;
  min-width: 300px;

  p{
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  &--small-text {
    p{
      font-size: 20px;
      line-height: 28px;
    }
  }
  img {
    max-width: 100%;
  }
  @include tablet{
    padding: 50px 0;
    flex-basis: 50%;
  }

  &--first-panel {
    @include tablet{
      padding-right: 24px;
    }
  
    @include desktop{
      padding-right: 48px;
    }
  }

  .results__footer-item--frog-promo & {
    margin-left: auto;
  }

}

.tile {
  margin-bottom: 16px;
  background-color: $white;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;

  @include tablet {
    margin-bottom: 48px;
  }
}


.tile__header {
  padding: 24px;
  font-size: 20px;

  border-top-left-radius: 16px; 
  border-top-right-radius: 16px;
  background-color: white;
  border-bottom: 0.5px solid #000000;
}

.tile__title {
  margin-bottom: 0;
  background: linear-gradient($primary2-light 30%, $primary2);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  font-family: "QuartoLight";
  margin-top: 0;
  line-height: 55px;
}
.tile__content {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.tile__section-name {
  font-weight: bold;
}


.tile__image-wrapper {
  width: 100%;
  text-align: right;
  overflow:hidden;
  pointer-events: none;
  transform: translateY(24px);
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: -64px;
  order: 10;

  @include tablet {
   margin-top: -92px;
  }

  @include tablet {
    margin-top: -128px;
   }
}

.tile__image {
  max-width: 175px;
  position: relative;
  bottom:-20px;
  left:-20px;
}

.cfh-promo__title{
  font-family: "QuartoLight";
  font-size: 60px;
  font-weight: 300;
  line-height: 60px;
  text-align: left;
  margin-bottom: 16px;
}

.cfh-promo__description{
font-family: GTAmerica;
font-size: 20px;
font-weight: 400;
line-height: 28px;
text-align: left;

}

.results__footer__item-last {
  @include left-right-padding;
}

.tile__section--bold{
  font-family: "GTAmerica-medium";
  font-weight: 500;
}

.tile__description{
  font-family: "GTAmerica";
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  max-width: 514px;
  padding: 32px 0 32px;
  margin-bottom: 0;
}

h5{
  font-family: "GTAmerica";
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 0 0 16px 0;
}
