@import "../../styles/variables";

.radio-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 16px;
  flex-wrap: wrap;
  max-width: 100%;
  flex-direction: column;

  @include tablet {
    max-width: 720px;
    flex-wrap: nowrap;
    margin: 16px 0 0;
  }

}

.radio-group__radios {
  display: flex;
  order: 1;
  flex-basis: 100%;
  margin-top: 8px;
  width: 335px;
  margin: -4px;
  justify-content: space-between!important;
  @include tablet {
    order: 1;
    width: 440px;
  }
}


.radio-group__radio {
  display: flex;
  margin-bottom: 12px;
  padding: 4px;
}

.radio-group__label {
  flex-basis: 50%;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 0;
  &--positive {
    order: 3;
    text-align: right;
  }

  &--negative {
    order: 2;
    text-align: left;
  }

  @include tablet {
    flex-basis: 16%;

    &--negative {
      order: 1;
    }
  }
}

.radio-group__labels {
  display: flex;
  justify-content: space-between;
  width: 440px;
  @include mobile {
    width: 335px;
  }
}

.radio-group__key-label {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  border: 1px solid $black;
  border-radius:5px;
  padding: 4px 4px;
  width: 52px;
  height: 26px;
  position: relative;
  bottom: -65px;
  left: -66px;
  margin-right: -50px;
  // display:contents;
  background-color: $neutrals-grey-10-bg-grey;

  @include mobile {
    display: none;
  }
  @include tablet {
    display: none;
  }
  @include desktop {
    display: block;
  }
}

.radio-group__key-label-checked{
  background-color: $black;
  color: $white;
}

.radio-group__key-label-checked:focus{
  background-color: $white;
  color: $white;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  cursor: pointer;
  line-height: 20px;
  font-size: 20px;
  border: 1px solid $black;
  border-radius: 5px;
  background-color: $white;
  color: $black;
  @include tablet {
    width: 80px;
    height: 80px;
  }
}

.radio-group__radio--5 [type="radio"]:checked + label,
.radio-group__radio--5 [type="radio"]:not(:checked) + label {
  border-right: 0;
}

[type="radio"]:not(:checked) + label:hover{
  background:  $dark-grey;
  color: $white;
}
[type="radio"]:checked + label:hover{
  background:  $dark-grey;
}

[type="radio"]:checked + label {
  background: $black;
  color: $white;
}
[type="radio"]:focus +label {
  outline: solid $black 2px;
  background-color: $white;
  color:$black;
}

[type="radio"]:focus:hover +label {
  outline: solid $black 2px;
  border: solid $white 2px;
  background-color: $dark-grey;
  color:$white;
}

[type="radio"]:checked:focus + label {
  border: solid $white 2px;
  background: $black;
  color: $white;
}

[type="radio"]:checked:focus:hover + label {
  border: solid $white 2px;
  background: $dark-grey;
  color: $white;
}

@media only screen and (max-width: 600px) {
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    padding: 18px;
  }
}
