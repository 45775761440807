@font-face {
  font-family: "GTAmerica";
  src: url("../src/fonts/GT-America-Standard-Regular.woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "GTAmerica-medium";
  src: url("../src/fonts/GT-America-Standard-Medium.woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "GTAmerica-bold";
  src: url("../src/fonts/GT-America-Standard-Bold.woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "QuartoLight";
  src: url("../src/fonts/Quarto-Light.woff2");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Quarto-italic";
  src: url("../src/fonts/Quarto-LightItalic.woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 100%;
  font-family: "GTAmerica", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
a {
  outline-offset: 4px;
  outline-width: 2px;
  outline-color: black;
  cursor: pointer;
  text-underline-offset: 6px;
}
* {
  box-sizing: border-box;
}

main {
  position: relative;
}
