.handglobe {
	width: 100%;
	position: relative;
}

.handglobe__fg {
  width: 100%;
  max-height: 100%;
  position: relative;
  z-index: 2;
}

.handglobe__bg {
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
  height: 60%;
  object-fit: cover;
  position: absolute;
  filter: hue-rotate(16deg);
  z-index: 1;
}
