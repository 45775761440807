@import '../../styles/variables.scss';

.not-found {
  width: 100%;
  min-height: calc(100dvh - 140px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-found__content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px;
  width: 100%;
  max-width: 640px;

  > svg {
    max-width: 100%;
  }
}

.not-found__container--animate {
  animation: rotate 20s infinite linear;
}

.not-found__title {
  font-family: "QuartoLight", serif;
  margin: 0;
  font-size: 96px;
  line-height: 96px;
  background-size: cover;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
  filter: hue-rotate(16deg);

  .not-found__container--animate & {
    animation: bob 15s infinite ease-in-out
  }

  @include tablet {
    font-size: 240px;
    line-height: 320px;
  }
}

@keyframes bob {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2.5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-2.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}