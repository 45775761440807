@import "../../styles/variables";

.sidebar {
  background: $background;
  height: 100%;
  max-width: 600px;
  overflow-y: auto;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  z-index: 20;
  padding: 100px 20px 40px;
  transition: transform 0.5s;
  transform: translateX(100%);
  &[aria-hidden="true"] {
    * {
      display: none;
    }
  }

  &--open {
    transform: translateX(0);
  }


  ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      margin-bottom: 0;
    }

  }

  @include tablet {
    padding: 88px 52px 52px;
  }
}

.sidebar__section {
  &:not(:last-child) {
    margin-bottom: 64px;
  }

  & h2 {
    font-family: "QuartoLight";
    font-weight: 300;
    letter-spacing: 0em;
    margin-top: 0;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;

    @include tablet {
      font-size: 32px;
      line-height: 44px;
    }
  }
  p{
    font-family: "GTAmerica";
    font-weight: 400;
    line-height: 180%;
    font-size: 18px;
  }
  li{
    font-family: "GTAmerica";
    font-weight: 400;
    line-height: 180%;
    font-size: 18px;
  }
}

.sidebar__close {
  background: none;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 2px;

  @include tablet {
    top: 24px;
    right: 24px;
  }
}

.sidebar__instructions {
  padding-inline-start: 20px;

  li {
    list-style: decimal;
  }
}

.sidebar__link-list {
  padding-left: 0;
  li {
    margin-bottom: 0;
  }

  &--no-seperator {
    li {
      &:not(:last-child) {
        border-bottom: 0;
      }
    }
  }
}

.sidebar__cta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 10px 12px 0px;
  color: $black;

  img {
    margin-left: 8px;
    pointer-events: none;
  }
}
.sidebar__social-icons{
  @include mobile{
    margin-top:16px;
  }
  display: flex;
  flex-direction: row;
}

.sidebar__social-icon {
  background-color: $white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  
  img {
    pointer-events: none;
  }
  &:hover{
    filter: invert(1);
  }
}

.sidebar__idean-links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  flex-direction: row;
  a:first-child {
    margin-bottom: 16px;
    width: 100%;
    @include tablet {
      width: auto;
      margin-right: 46px;
    }
    
    img {
      width: auto;
      height: 50px
    }
  }
}

.sidebar__footer-links {
  text-decoration: none;
  font-weight: 700;
  font-size: 17px;
  line-height: 32px;
}

p.sidebar__copyright {
  font-weight: 400;
  line-height: 180%;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0;
}

.sidebar__idean-logo {
  img {
    pointer-events: none;
  }
}