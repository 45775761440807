@import '../../styles/variables';

.question {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include mobile {
    margin-top: 16px;
  }

  h1.question__title {
    margin: 0;
    margin-top: 16px;
    font-size: 24px;
    line-height: 32px;

    @include tablet {
      margin: 12px 0;
      font-size: 48px;
      line-height: 56px;
    }
  }

  .question__detail {
    margin-top: 16px;
    font-size: 16px;
    line-height: 26px;
    
    @include tablet {
      font-size: 20px;
      line-height: 28px;
      margin-top: 4px;
      text-align: left;
    }
  }
}