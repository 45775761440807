@import '../../styles/variables';

.home__title {
  font-family: QuartoLight;
  font-size: 96px;
  font-style: normal;
  font-weight: 300;
  line-height: 84%;
  text-align: left; 
  margin-bottom: 40px;
  margin-top: 0;
  @media screen and (max-width: 1100px) {
    margin:0 0 24px 0;
    width:251px;
    font-family: QuartoLight;
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    line-height: 52px;
  }
}
.home__subtitle{
  text-align: left;
  font-family: GTAmerica-medium;
  font-size: 20px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.home__intro {
  max-width: 600px;
  text-align: left;
  font-size: 16px;
  margin-bottom: 16px;
  @include tablet {
    font-size: 18px;
    margin-bottom: 36px;
  }
}

.home__image {
  z-index: -1;
  position: fixed;
  bottom: -40px;
  right: -20px;
  z-index: -1;
  margin-top: 0;
  width: 240px;

  @include tablet {
    width: 330px;
    right: 30px;
    bottom: 0px;
  }

  @include desktop {
    width: 580px;
    bottom: -32px;
    right: 64px;
  }

  @include desktopLarge {
    width: 800px;
    bottom: -32px;
    right: 200px;
  }

  @include desktopExtraLarge {
    width: 800px;
    bottom: -32px;
    transform: translateX(-50%);
    left: calc(50% + 360px);
  }
}

.continent1 ,.continent2 ,.continent3{
  fill: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	animation: gradient 15s ease infinite;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.home__left-container{
  width: 100%;
  max-width: 480px;
  @include left-right-margin;
}

.home__right-container{
  margin-top: 173px;
  @media screen and (max-width: 1100px) {
    margin:0;
  }
}

.home__content{
  display: flex;
  justify-content: unset;
  max-width: $site-width;
  margin-left: auto;
  margin-right: auto;
  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    flex-direction: column;
  }
}

.home__cta-text--bold{
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: "GTAmerica-medium";
}

.home__cta-container{
  display: flex;
  align-items: center;
}
.home__cta{
  display: none;
  @include desktop {
    display: flex;
    align-items: center;
    margin-left:16px;
    > * {
      margin: 0 4px;
    }

    > svg {
      margin-top: 2px;
    }
  }

}