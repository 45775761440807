@import '../../styles/variables.scss';

ul.external-links {
  list-style-type: none;
  padding-left: 0;
  color: $black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 110;
  position: relative;

  li {
    margin-bottom: 12px;
  }

  a.external-links__link:link,
  a.external-links__link:visited {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    text-underline-offset: 6px;
    color: $black;

    span {
      padding: 0px 4px 4px 0;
      flex-shrink: 1;
    }
    svg {
      flex-shrink: 0;
      margin-left: 4px;
    }
  }

  &--inverse {
    color: $white;
    a.external-links__link:link,
    a.external-links__link:visited {
      background-color: $black;
      color: $white;
    }
  }
  
  .external-links__source {
    margin: 0;
    margin-top: -5px;
    color: $source-grey;
  }

}
