@import "../../styles/variables";

.page {
  width: 100vw;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 12px;
  height: 100%;

  @include tablet {
    padding-top: 32px;
    padding-bottom: 12px;
  }
  @include desktop {
    padding-top: 60px;
    padding-bottom: 12px;
  }


  &--home {
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    position: relative;
  }

  &--results {
    padding-top: 32px;
    padding-bottom: 0;
    @include mobile {
      padding-top: 24px;
    }
  }
  &--interstitial {
    display: flex;
    align-items: center;
    position: relative;

    .page-title span {
      background-color: $background;
    }

    @include mobile {
      padding-top: 24px;
    }
  }

  &--questionaire {
    max-width: $question-width;
    display: flex;
    align-items: center;
    @include mobile {
      padding-top: 0;
    }
  }
}
.page__section-icon{
  z-index: -1;
  position: fixed;
  bottom: 0;
  right: 12px;
  z-index: -1;
  margin-top: 0;
  width: 180px;
  height: auto;

  @include tablet {
    width: 240px;
    bottom: 0;
    right: 32px
  }

  @include desktop {
    width: 330px;
    bottom: 0;
    right: 64px;
  }

  @include desktopExtraLarge {
    transform: translateX(-100%);
    left: calc(50% + (#{$site-width} / 2) - 64px);
  }
}
.page__Anchor-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top:0px;
  font-size: 14px;
  @include tablet {
    margin-top: 32px;

  }
}
.page__blurb {
  max-width: 470px;
  margin: 16px 0 32px;
}

.page__content {
  margin: 0 auto;
  padding: 16px 20px 32px;
  max-width: $question-width;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &--details {
    flex-grow: 1;
  }

  @include tablet {
    padding: 24px 48px;
  }
}

.page__section-name {
  font-size: 18px;
  margin-bottom: 12px;

  @include tablet {
    font-size: 18px;
    margin-bottom: 0;
  }
  @include mobile {
    font-size:16px;
    margin-bottom: 0;
    line-height: 19.2px;
  }
  
}

.page__content__ending {
  margin-top: 52px;
}

.page__image {
  margin: 0 auto;
  max-width: 800px;
  max-height: 400px;
  width: 74%;
  flex-grow: 1;

  img {
    max-height: 250px;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    @include desktop {
      z-index: 3;
      max-height: 288px;
      position: relative;
      overflow: hidden;
    }
  }
}

.page__skip {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.page__skip-link {
  font-size: 14px;
  text-underline-offset: 6px;
}

@media screen and (max-width: 1100px) {
  .page__content {
    flex-basis: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .page__content {
    flex-basis: 100%;
  }

  .page__title-break {
    display: none;
  }
}
