@import "./_variables";

body {
  background-color: $background;
  font-family: "GTAmerica", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 54px;
  font-family: "GTAmerica", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 800;

  &.page-title {
    font-size: 20px;
    max-width: $question-width;
    text-align: left;
    margin-top: 0;
    margin-bottom: 35px;
    line-height: 1.2em;
    font-weight: 400;
    @include mobile {
      font-size: 24px;
      margin-top: 16px;
    }
  }

  @include tablet {
    font-size: 64px;
    &.page-title {
      font-size: 48px;
      margin-top: 32px;
    }
  }

  @include desktop {
    font-size: 76px;
    line-height: 84px;
  }
}

h2 {
  font-size: 20px;
  line-height: 32px;
  font-family: "GTAmerica", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 500;

  @include tablet {
    font-size: 32px;
  }
}
h2 span {
  font-family: "GTAmerica", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 200;
}
h3 {
  font-size: 18px;
  line-height: 1.4em;
  font-family: "GTAmerica", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 500;
}

li,
p {
  font-size: 14px;
  line-height: 28px;
  margin: 0 0 16px 0;

  @include mobile {
    font-size: 18px;
    line-height: 32px;
  }
}

li {
  margin-bottom: 16px;
  list-style: none;
}

ul {
  padding-inline-start: 32px;
}

a:link,
a:visited {
  color: inherit;
}

.header {
  position: absolute;
  width: 100vw;
  margin: 0 auto;
  z-index: 10;
}

.header__content {
  padding: 22px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include tablet {
    padding: 22px 48px 0 48px;
  }
  @include left-right-padding;
  max-width: $site-width;
  margin-left: auto;
  margin-right: auto;
}

.header__home-link {
  flex-shrink: 1;
  margin-right: 12px;

  svg {
    pointer-events: none;
  }
}

.header__svg {
  width: 100%;
}

.illustration {
  width: 400px;
  position: absolute;
  right: 104px;
}

.illustration--small {
  width: 380px;
}

.anchor {
  display: flex;
}

.button {
  font-weight: bold;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  border: none;
  &--text {
    background: none;
  }
  &--inverted {
    background: $white;
    color: $black;
    border-radius: 4px;
  }
}

.App-container {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  position: relative;

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .transition-container {
    flex-grow: 1;
    height: 100%;
    position: relative;
  }
}

.App__overlay {
  background: rgba($black, 0.24);
  content: "";
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 200ms ease-in-out;
  pointer-events: all;
  z-index: 15;

  &--visible {
    opacity: 1;
    visibility: visible;
  }
}

.header__about {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  font-family: "GTAmerica-medium";
}

.header__home {
  max-width: 220px;
  width: 100%;
  position: absolute;
  left: 20px;
  top: 20px;

  @include tablet {
    max-width: 275px;
    left: 24px;
    top: 24px;
  }

  @include desktop {
    left: 48px;
  }
}

.header__home-logo {
  width: 100%;
}

.header__home-link {
  position: absolute;
  left: 0;
  top: 0;
}

.page-enter {
  opacity: 0;
  position: relative;

  .page__content {
    transform: translateY(100px);
  }
}

.page-enter-active {
  transition: opacity 300ms;
  opacity: 1;

  .page__content {
    transition: transform 600ms;
    transform: translateY(0px);
  }
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  margin: auto;
}
