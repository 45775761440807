
$white: #FFF;
$black: #000;
$dark-grey: #393939;
$source-grey: #777777;
$neutrals-grey-10-bg-grey:#F4F4F4;

$background: #f4f4f4;

$primary1: #FF01B0;
$primary2: #6906E0;
$primary2-light: #9958FF;

$site-width: 1920px;
$question-width: 900px;

@mixin mobile {
 @media(max-width:719px) {
   @content;
 }
} 
@mixin tablet {
 @media(min-width: 720px) {
   @content;
 }
} 
@mixin desktop {
 @media(min-width: 1367px) {
   @content;
 }
} 
@mixin desktopLarge {
 @media(min-width: 1600px) {
   @content;
 }
} 

@mixin desktopExtraLarge {
  @media(min-width: $site-width) {
    @content;
  }
 } 

@mixin left-right-padding {
  padding-left: 16px;
  padding-right: 16px;

  @include tablet {
    padding-left: 22px;
    padding-right: 22px;
  }

  @include desktop() {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include desktopLarge() {
    padding-left: 48px;
    padding-right: 48px;
  }
}

@mixin left-right-margin {
  margin-left: 16px;
  margin-right: 16px;

  @include tablet {
    margin-left: 22px;
    margin-right: 22px;
  }

  @include desktop() {
    margin-left: 32px;
    margin-right: 32px;
  }

  @include desktopLarge() {
    margin-left: 48px;
    margin-right: 48px;
  }
}

@mixin button {
  display: inline-block;
  background-color: $black;
  border: 0;
  line-height: 28px;
  border-radius: 50px;
  padding: 14px 40px;
  color: $white;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  min-width: 120px;
  width: auto;
  max-width: none;
  text-align: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;


  &:link,
  &:visited {
    color: $white;
    text-decoration: none;
  }

  &--inverse {
    background: $white;
    color: $black;

    &:link,
    &:visited {
      background: $white;
      color: $black;
    }
  }

  &:disabled,
  &--disabled {
    opacity: .3;
    color: $white;
    cursor: not-allowed;
  }
  
  &:hover {
    background: $dark-grey;
    color: $white;
  }
  
  &--indented {
    padding: 12px 4px;
  }

  > * {
    padding: 4px 0;
  }
}