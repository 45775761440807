@import '../../styles/variables.scss';

.progressbar {
  position:fixed;
  top: 0px;
  left: 0px;
  height: 6px;
  width: 100%;
  background-color: #e0e0e0;
}

.progressbar__done {
  display: block;
  height: 6px;
  transition: width 2s;
  background-color: $primary2-light
}
