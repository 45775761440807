@import "../../styles/variables";

.page-nav {
  width: 100vw;
}

.page-nav__content {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px;
  padding-top: 0;
  height: 68px;
  max-width: $site-width;
  margin-left: auto;
  margin-right: auto;

  @include tablet {
    padding: 0 48px 22px 48px;
    padding-top: 0
  }

  @include left-right-padding;
}

.page-nav__steps {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  margin-right: 8px;
  margin-bottom: 2px;
}

.page-nav__progress {
  background-color: $white;
  width: 120px;
  height: 6px;
  display: flex;

  span {
    background-color: $black;
    height: 100%;
  }
}

.page-nav__links {
  display: flex;
}

.page-nav__link {
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: $white;
  margin-left: 4px;
  border-radius: 5px;
  z-index: 10;

  &--disabled {
    cursor: not-allowed;

    svg {
      opacity: 0.2;
    } 
  }

  svg {
    pointer-events: none;
  }

}
