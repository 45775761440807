@import '../../styles/variables';

.cookie-banner {
  bottom: 0;
  left: 0;
  right: 0;
  height: 58px;
  z-index: 4;
}

.cookie-banner__content {
  position: absolute;
  padding: 16px;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  color: $white;
  background: $primary2-light;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cookie-banner__links {
  flex: 1 0;
  text-align: center;
}

.cookie-banner__close {
  background: none;
	border: none;
  flex: 0 1;
}
